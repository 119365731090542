import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Slide from "react-reveal/Slide";
import Layout from "../components/layout"
import Seo from "../components/seo"
import WeWorkWith from "../components/WeWorkWith"

const Production = ({ data, location }) => {
  const { t, i18n } = useTranslation();

  const brand = (i18n.language === "tr" ? data.site?.siteMetadata?.titleTr : data.site?.siteMetadata?.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "QAPage",
    "mainEntity": {
      "@type": "Question",
      "name": t("production"),
      "answerCount": "1",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": t("meetingDescription") + " " + t("discussionDescription") + " " + t("decisionDescription") + " " + t("productionDescription") + " " + t("deliveryDescription") + " " + t("supportDescription"),
        "author": {
          "@type": "Organization",
          "name": brand,
          "location": "Tuzla Deri OSB Vakum Cad. No:13/A\nÇ3-3 34957 Tuzla / İstanbul",
          "identifier": brand + "\n\nAdres\n\nTuzla Deri OSB Vakum Cad. No:13/A\nÇ3-3 34957 Tuzla / İstanbul\n\nE-mail\n\niletisim@tbantsan.com\n\nTelefon\n\n+90 216 394 83 70"
        },
        "dateCreated": "2022-06-10T21:00:00.000Z",
        "url": data.site.siteMetadata.siteUrl + location.pathname
      }
    }
  }

  const seo = {
    title: t("metaProductionTitle"),
    description: t("metaProductionDescription"),
    keywords: t("metaProductionKeywords"),
    schema: schema
  }

  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo {...seo} />

      <div className="bg-reallyDarkBlue text-white relative">
        <div className="max-w-7xl px-6 md:px-10 py-10 mx-auto">
          <div>
            <Slide bottom duration={1000}>
              <h1 className="text-4xl md:text-6xl w-max font-bold mx-auto">
                {t("production")}
              </h1>
            </Slide>
            <div className="w-full my-20">
              <StaticImage
                src="../images/production-hero-image.png"
                alt={t("production")}
              />
            </div>
          </div>
          <div className="md:py-24">
            <Slide left duration={1000}>
              <h2 className="text-4xl md:text-6xl w-max font-bold">{t("process")}</h2>
            </Slide>
            <div className="md:flex flex-wrap justify-between pt-14">
              <div className="py-8 md:py-12 md:w-2/5">
                <Slide bottom duration={1000}>
                  <h3 className="text-lg font-bold pb-5">{t("meetingTitle")}</h3>
                </Slide>
                <Slide bottom duration={1000}>
                  <p className="text-lg font-light">
                    {t("meetingDescription")}
                  </p>
                </Slide>
              </div>
              <div className="py-8 md:py-12 md:w-2/5">
                <Slide bottom duration={1000}>
                  <h3 className="text-lg font-bold pb-5">{t("discussionTitle")}</h3>
                </Slide>
                <Slide bottom duration={1000}>
                  <p className="text-lg font-light">
                    {t("discussionDescription")}
                  </p>
                </Slide>
              </div>
              <div className="py-8 md:py-12 md:w-2/5">
                <Slide bottom duration={1000}>
                  <h3 className="text-lg font-bold pb-5">{t("decisionTitle")}</h3>
                </Slide>
                <Slide bottom duration={1000}>
                  <p className="text-lg font-light">
                    {t("decisionDescription")}
                  </p>
                </Slide>
              </div>
              <div className="py-8 md:py-12 md:w-2/5">
                <Slide bottom duration={1000}>
                  <h3 className="text-lg font-bold pb-5">{t("productionTitle")}</h3>
                </Slide>
                <Slide bottom duration={1000}>
                  <p className="text-lg font-light">
                    {t("productionDescription")}
                  </p>
                </Slide>
              </div>
              <div className="py-8 md:py-12 md:w-2/5">
                <Slide bottom duration={1000}>
                  <h3 className="text-lg font-bold pb-5">{t("deliveryTitle")}</h3>
                </Slide>
                <Slide bottom duration={1000}>
                  <p className="text-lg font-light">
                    {t("deliveryDescription")}
                  </p>
                </Slide>
              </div>
              <div className="py-8 md:py-12 md:w-2/5">
                <Slide bottom duration={1000}>
                  <h3 className="text-lg font-bold  pb-5">{t("supportTitle")}</h3>
                </Slide>
                <Slide bottom duration={1000}>
                  <p className="text-lg font-light">
                    {t("supportDescription")}
                  </p>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="w-full my-20">
            <div className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 mx-auto">
              <Slide bottom duration={1000}>
                <p className="text-white text-4xl md:text-6xl text-center font-bold">{t("withAnyMaterialYouWant")}</p>
              </Slide>
            </div>
            <StaticImage
              src="../images/production-section.png"
              alt={t("withAnyMaterialYouWant")}
            />
          </div>
        </div>
        <WeWorkWith />
      </div>
    </Layout>
  )
}

export default Production

export const query = graphql`
   query ($language: String!){
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`